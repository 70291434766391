<template>
  <div class="inner job-posting">
    <sub-title
      title="출 · 퇴근"
      description="근무시작 10분전에 도착해서 근무를 준비하시기 바랍니다."
    >
    </sub-title>
    <router-view></router-view>
  </div>
</template>

<script>
import SubTitle from 'Common/SubTitle'

export default {
  components: { SubTitle },
}
</script>

<style></style>
